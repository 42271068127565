<template>
  <div class="alarm_data">
    <dec-block-title>报警信息</dec-block-title>
    <vue-seamless-scroll :data="contentData" class="seamless-warp">
      <ul class="item">
        <li v-for="(item, index) in contentData" :key="index">
          <p>报警类型：{{ item.type }}</p>
          <p>报警点：{{ item.LocaleName }}</p>
          <p>报警时间：{{ dateFormat(item.AcquitAt) }}</p>
          <p>报警地址：{{ item.LocaleAddress }}</p>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import decBlockTitle from "../decorations/blockTitle2";
import vueSeamlessScroll from "vue-seamless-scroll";
import { dateFormater } from "@/util/index";

export default {
  name: "alarmData",
  components: {
    decBlockTitle,
    vueSeamlessScroll,
  },
  props: {
    contentData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    dateFormat(date) {
      return dateFormater(date);
    },
  },
};
</script>

<style lang="scss" scoped>
.alarm_data {
  width: 100%;
  box-sizing: border-box;
  .seamless-warp {
    height: 400px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    .item {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 12px;
      li {
        color: #fff;
        // border-bottom: 1px solid #fff;
        overflow: hidden;
        padding-left: 12px;
        font-size: 13px;
        line-height: 13px;
        &:nth-child(odd) {
          background: rgba(31, 77, 135, 0.2);
        }
      }
    }
  }
}
</style>
